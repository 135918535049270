<template>
    <!-- 客户列表 -->
    <div style="height:100%;background:#fff;padding-top:0.7rem;">
        <div class="autoFucus" style="position:fixed;top:0;left:0;z-index: 888;">
            <nut-cell :show-icon="true" title="" :desc="searchform.typeName" custom-class-name='typeClass' @click.native="openSwitchType('typeIsVisible')"></nut-cell>
            <nut-picker :is-visible="typeIsVisible" :list-data="typeoptions" title="客户类型" @close="closeSwitchType('typeIsVisible')" @confirm="confirmType"></nut-picker>

            <nut-textinput  class="my-input" type="search" v-model="searchform.searchname"  placeholder="请输入客户名称/手机号" ref="myInput" @keyup.enter="submit" >
                <template v-slot:clearBtn>
                    <nut-icon type="cross"></nut-icon>
                </template>
            </nut-textinput>
            <div class="searchBtn" @click="submit">搜索</div>
        </div>
        <!-- border-bottom:1px solid red; -->
        <nut-scroller ref="my_scroller" 
            :is-un-more="isUnMore" 
            :is-loading="isLoading"
            :type="'vertical'"
            :scrollTo="scrollTo"
            @loadMore="loadMoreVert"
            @pulldown="pulldown"
            @scrollChange="scrollChange"
            :loadingTxt="'加载中...'"
            style="height:calc(100% - 1.3rem);"
        > 
            <div slot="list" class="nut-vert-list-panel">
                <div @click="getCustomerDetail(item)" class="nut-vert-list-item" v-for="(item, index) of customerList" :key="index">
                    <nut-cell :show-icon="true">
                        <span slot="title">
                            <span @click.stop="showName(item)">
                                {{item.nameCopy}}
                                <i :class="!item.nameChange?'iconfont icon-biyanjing':'iconfont icon-yanjing'" style="color:#2bc58f;margin-left:0.06rem;"></i>
                            </span>
                        </span>
                        <span slot="desc" style="font-size:14px;">
                            <span @click.stop="showPhone(item)">
                                {{item.phoneCopy}}
                                <i :class="!item.phoneChange?'iconfont icon-biyanjing':'iconfont icon-yanjing'" style="color:#2bc58f;margin-left:0.06rem;"></i>
                            </span>
                        </span>
                    </nut-cell>
                </div>
            </div>
        </nut-scroller>
        
        <!-- <div>
            <van-list class="listClass"
                v-model="isLoading"
                :finished="isUnMore"
                finished-text="没有更多了"
                @load="onLoad"
            >
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-cell v-for="item in list" :key="item" :title="item" />
                </van-pull-refresh>
            </van-list>
        </div> -->
    </div>
</template>
<script>
import { mapState } from "vuex";
import { customerList,customerView,customerPhoneView } from '@/api/api.js'
import InfiniteLoading from 'vue-infinite-loading'
export default {
    components: {
        InfiniteLoading
    },
    data(){
        return {
            typeIsVisible: false,
            typeoptions: [
                [{label:'2',value:'私海客户',},
                // {label:'6',value:'跨期客户',},
                {label:'4',value:'公海客户',},
                {label:'5',value:'出单客户',},
                {label:'7',value:'企微绑定客户',}]
            ],
            customerList:[],
            searchform:{
                searchname:'',
                name:'',
                phone:'',
                typeName: '私海客户',
                type:'2',
                page: 1,
                pageNum:16,
            },
            maxPages: 4,
            isUnMore: false,
            isLoading: false,
            timer: null,

            list: [],
            loading: false,
            finished: false,
            refreshing:false,
            scrollTo:-100,
            loadingport:false,
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.getcustomerList();
    },
    computed: {
      ...mapState(['userInfo']),
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
    },
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        //选择客户类型
        openSwitchType(param) {
            this[`${param}`] = true;
        },
        closeSwitchType(param) {
            this[`${param}`] = false;
        },
        confirmType(chooseData) {
            this.scrollTo=0;
            this.searchform.typeName = `${chooseData[0].value}`;
            this.searchform.type = `${chooseData[0].label}`;
            // console.log(this.searchform.type);
            this.searchform.page=1;
            this.searchform.pageNum=16;
            this.customerList = [];
            this.getcustomerList();
        },
        scrollChange(value){
            this.scrollTo=value;
        },
        submit(){
            this.scrollTo=0;

            this.searchform.page=1;
            this.searchform.pageNum=16;
            if(/^1\d{10}$/.test(this.searchform.searchname)){
                this.searchform.phone=this.searchform.searchname;
            }else{
                this.searchform.name=this.searchform.searchname;
            }
            this.customerList = [];
            this.getcustomerList();
        },
        infiniteHandler($state) {
            // console.log($state)
        },
        onLoad() {
            console.log("触发了load事件");
            if (this.refreshing) {
                this.customerList = [];
                this.refreshing = false;
            }

            // for (let i = 0; i < 20; i++) {
            //     this.list.push(this.list.length + 1);
            // }
            // this.loading = false;

            // if (this.list.length >= 40) {
            //     this.finished = true;
            // }

            this.customerList = [];
            this.isLoading = true;
            this.searchform.page = 1;
            if (this.searchform.page > this.maxPages) {
                this.isUnMore = true;
                this.isLoading = false;
            } else {
                this.searchform.page = ++this.searchform.page;
                // this.getcustomerList();
            }
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;

            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            // this.onLoad();
            if (this.searchform.page > this.maxPages) {
                this.isUnMore = true;
                this.isLoading = false;
            } else {
                this.searchform.page = ++this.searchform.page;
                this.getcustomerList();
            }
        },

        loadMoreVert() {
            this.isLoading = true;
            if (this.searchform.page > this.maxPages) {
                this.isUnMore = true;
                this.isLoading = false;
            } else {
                this.searchform.page = ++this.searchform.page;
                this.getcustomerList();
            }
        },
        pulldown() {
            this.customerList = [];
            this.isLoading = true;
            // clearTimeout(this.timer);
            // this.timer = setTimeout(() => {
            //     this.isLoading = false;
            //     this.isUnMore = false;
            //     this.customerList = new Array(20);
            //     this.searchform.page = 2;
            // }, 3000);
            this.searchform.page = 1;
            this.getcustomerList();
        },
        getcustomerList(){
            let parmas = this.searchform;
            customerList(parmas).then(res => {
                if(res.data.code==200){
                    res.data.data.data.forEach(item => {
                        item.nameCopy=item.desensitise_name;
                        item.nameChange=false;
                        item.phoneCopy=item.desensitise_phone;
                        item.phoneChange=false;
                    });
                    this.isLoading = false;
                    this.isUnMore = false;
                    this.customerList = [...this.customerList, ...res.data.data.data];
                    this.maxPages=Math.ceil(res.data.data.total/20)
                    // console.log(this.maxPages,this.customerList);
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                }
            })
        },
        getCustomerDetail(data){
            this.$router.push({
                path:'/customerDetails',
                query:{
                    id:data.id
                }
            })
        },
        //客户信息解密
        async showName(data){
            if(!data.nameChange){
                let returnData=await this.getcustomerView(data.id,1,3,'');
                data.nameCopy=returnData;
                data.nameChange=returnData?true:false;
            }else{
                data.nameCopy=data.desensitise_name;
                data.nameChange=false;
            }
        },
        async showPhone(data){
            if(!data.phoneChange){
                let returnData=await this.getcustomerView(data.id,2,3,'');
                data.phoneCopy=returnData;
                data.phoneChange=returnData?true:false;
            }else{
                data.phoneCopy=data.desensitise_phone;
                data.phoneChange=false;
            }
        },
        copyText(phone){
            let that=this;
            this.$copyText(phone).then(function(){
                that.$toast.success("内容已复制到剪切板!",{duration: 2000});
            }, function(e) {

            });
        },
        async getcustomerView(customer_id,type,source,oid){
            if (this.loadingport) {
                return
            }
            this.loadingport=true;
            let decryptData='';
            let parmas = {customer_id,type,source,oid,};
            await customerView(parmas).then(res => {
                if(res.data.code==200){
                    decryptData=res.data.data.decryptData;
                    this.loadingport=false;
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                    this.loadingport=false;
                }
            });
            return decryptData
        },
        //客户信息解密end
    }
}
</script>
<style scoped>
.fontStyle{
    color:rgb(43, 197, 143);
}
.listClass {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
.autoFucus {
    background: #fff;
    padding: 0.1rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width:calc(100% - 20px);
    /* margin:0 auto; */
}
.autoFucus .my-input {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.autoFucus .searchBtn {
    margin-left: 10px
}
.searchBtn{
    font-size: 14px;
}
.infinite-loading-wrap{
    font-size: 12px;
    text-align: center;
}
</style>
<style>
    .autoFucus .nut-textinput input{
        height: 0.4rem;
    }
    .autoFucus .nut-cell:only-of-type{
        background: none;
    }
    .autoFucus .nut-cell-desc{
        font-size: 14px;
        color:#409eff;
    }
    /* .autoFucus .nut-cell:only-of-type{
        background: none;
    }
    .autoFucus .nut-cell:only-of-type{
        background: none;
    } */
    .nut-icon svg{
        margin-left:0.04rem;
    }
</style>
