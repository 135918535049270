import http from '../utils/http'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
// let resquest ="https://dev.finsen.scrm.essesuit.com";//测试地址
let resquest="https://finsen.scrm.essesuit.com";//正式地址

// post请求 登陆
export function requestLogin(params){
    return http.post(`${resquest}/api/user/login`,params)
}
// 验证码短信
export function sendCode(params){
    return http.post(`${resquest}/api/sms/sendCode`,params)
}
// post请求 绑定
export function wechatBindUser(params){
    return http.post(`${resquest}/api/user/wechatBindUser`,params)
}
// 微信登陆
export function wechatLogin(params){
    return http.post(`${resquest}/api/user/wechatLogin`,params)
}
// 退出登陆
export function loginOut(params){
    return http.post(`${resquest}/api/user/loginOut`,params)
}
//数据脱敏
export function customerView(params){
    return http.post(`${resquest}/api/customer/customerView`,params)
}
//数据脱敏-不限制（仅限外呼）
export function customerPhoneView(params){
    return http.post(`${resquest}/api/customer/customerPhoneView`,params)
}

// 客户列表
export function customerList(params){
    return http.post(`${resquest}/api/customer/index`,params)
}
// 客户详情
export function customerDetail(params){
    return http.post(`${resquest}/api/customer/detail`,params)
}
// 客户详情-操作客户记录
export function customerHandleLog(params){
    return http.post(`${resquest}/api/customer/handleLog`,params)
}
// 客户详情-原订单数据
export function orderList(params){
    return http.post(`${resquest}/api/customer/order`,params)
}
// 我的订单
export function getMyOrder(params){
    return http.post(`${resquest}/api/customer/getMyOrder`,params)
}
// 我的订单详情
export function myOrderDetail(params){
    return http.post(`${resquest}/api/order/getOrderInfo`,params)
}
// 我的订单详情（未付款重新生成订单二维码）
export function createOrderPayCode(params){
    return http.post(`${resquest}/api/customer/createOrderPayCode`,params)
}
// 我的订单详情（取消付款）
export function cancelOrderPay(params){
    return http.post(`${resquest}/api/customer/cancelOrderPay`,params)
}
// 修改我的订单支付状态(财务)
export function setMyOrderStatus(params){
    return http.post(`${resquest}/api/customer/setMyOrderStatus`,params)
}
// 我的订单详情 取消支付单<已支付修改未支付、财务>
export function orderCancelPay(params){
    return http.post(`${resquest}/api/order/orderCancelPay`,params)
}
// 下单、生成订单
export function createOrder(params){
    return http.post(`${resquest}/api/goods/createOrder`,params)
}
// 下单商品列表
export function getGoodsCouponList(params){
    return http.post(`${resquest}/api/goods/getGoodsListNew`,params)
}
// 获取可用优惠券
export function getCouponList(params){
    return http.post(`${resquest}/api/goods/couponListNo`,params)
}
// 获取销售标签
export function selltagList(params){
    return http.post(`${resquest}/api/order/orderTagList`,params)
}

/*********************** 公共接口 ****************************** */
export const uploadFiles =resquest + '/api/upload/oss'; //文件上传



